/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
window.jQuery = $;
window.$ = $;
require('./bootstrap.bundle.min.js')
require('./chosen.jquery.min.js')
require('./chart.bundle.min.js')
require('./datatables.min.js')
require('./select2.min.js')
require('./tinymce/tinymce.min.js')
require('./tinymce/tinymce.min.js')
require('./tinymce/jquery.tinymce.min.js')
require('./bootstrap-notify.min.js')
//require.context(
//  'file?name=[path][name].[ext]&context=./tinymce/skins',
//  true,
//  /.*/
//);



//var dt = require( 'datatables.net' )( window, $ );

$('[data-tooltip="tooltip"]').tooltip();

function formatSingleResult($ctrl, result) {
	var term = $ctrl.data("select2").dropdown.$search.val();
	var reg = new RegExp(term, 'gi');
	var optionText = result.text;
	var boldTermText = "";
	if(term!=""){
		boldTermText = optionText.replace(reg, function(optionText) {return `<mark class="bg-warning text-white">${optionText}</mark>`});
	}
	var $item = $(`<span>${boldTermText}</span>`);
	return $item;
}
window.formatSingleResult = formatSingleResult;

$.fn.waitingAnimation = function() {
	if(this.find("i").length>0){
		$(this).find("i").removeClass().addClass("far fa-spinner fa-spin");
	} else {
		$newEl = $("<i></i>").addClass("far fa-spinner fa-spin");
		$(this).prepend($newEl);
	}
	$(this).prop("disabled", true);
	return this;
};

function doAjaxCall($ctrl, reload){
	$.ajax({
	    type: 'GET',
	    url: $ctrl.attr("href")
	}).done(function(result){
		var type = "danger";
		var message = "Die Änderung konnte nicht durchgeführt werden: " + result;
		if(result==true){
			type = "success";
			message = "Änderung erfolgreich übernommen";
		}
		$.notify({
			// options
			message: message 
		},{
			// settings
			type: type
		});
		if(type=="success" && reload){
			window.location = window.location;
		}
	});
}

$(".call-ajax-with-msg").click(function(e){
	e.preventDefault();
	doAjaxCall($(this), false);
});

$(".call-ajax-with-msg-and-reload").click(function(e){
	e.preventDefault();
	doAjaxCall($(this), true);
});

$(".btn-statement-file").click(function(e){
	e.preventDefault();
	var id = $(this).attr("data-id");
	var action = $(this).attr("data-action");
	$.ajax({
		url: "/ajax/statementFile",
		data: { "id":id, "action":action },
		method: "POST"
	}).done(function(result){
		$.notify({
			message: "Änderung erfolgreich übernommen"
		},{
			type: "success"
		});
		window.location=window.location;
	});
});

$(".btn-fix-unfix").click(function(e){
	e.preventDefault();
	var id = $(this).attr("data-id");
	$.ajax({
		url: "/ajax/setFixUnfix",
		data: { "id":id },
		method: "POST"
	}).done(function(result){
		$.notify({
			message: "Änderung erfolgreich übernommen"
		},{
			type: "success"
		});
		window.location=window.location;
	});
});


var $clickedPayButton;
	
$(document).on('shown.bs.modal', '#modal-payed-at', function (event) {
	$clickedPayButton = $(event.relatedTarget);
});

$(".btn-save-payed").click(function(e){
	e.preventDefault();
	var selectedDate = $("input[name=payedAtDate]").val();
	$.ajax({
		url: "/ajax/setPayed",
		data: { "statementId":$clickedPayButton.attr("data-id"), "date": selectedDate },
		method: "POST"
	}).done(function(result){
		if(result==true){
			$clickedPayButton.parents("tr").addClass("table-success");
			$clickedPayButton.parents("tr").find(".td-payedat").text(selectedDate);
			$clickedPayButton.parents("tr").find(".btn-unset-payed").removeClass("d-none");
			$clickedPayButton.parents("tr").find(".btn-set-payed").addClass("d-none");
		}
	});
	$('#modal-payed-at').modal('hide');
});

$(".btn-unset-payed").click(function(e){
	$btn = $(this);
	$.ajax({
		url: "/ajax/unsetPayed",
		data: { "statementId":$(this).attr("data-id") },
		method: "POST"
	}).done(function(result){
		if(result==true){
			$btn.parents("tr").find(".btn-unset-payed").addClass("d-none");
			$btn.parents("tr").find(".btn-set-payed").removeClass("d-none");
			$btn.parents("tr").find(".td-payedat").text("");
			$btn.parents("tr").removeClass("table-success");
		}
	});
});

function fillSelect2AfterSearch(ctrlName, searchUrl, value){
	if(value!=""){
		$.ajax({
		    type: 'GET',
		    url: searchUrl + value
		}).then(function (data) {
			if(data.results.length>0){
			    // create the option and append to Select2
			    var option = new Option(data.results[0].text, data.results[0].id, true, true);
			    $("select[name=" + ctrlName + "]").append(option).trigger('change');

			    // manually trigger the `select2:select` event
			    $("select[name=" + crtlName + "]").trigger({
			        type: 'select2:select',
			        params: {
			            data: data
			        }
			    });
			}
		});
	}
}
window.fillSelect2AfterSearch = fillSelect2AfterSearch;


$(function() {
	$('[data-toggle="offcanvas"]').on("click", function() {
		$('.sidebar-offcanvas').toggleClass('active')
	});
});

//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');
